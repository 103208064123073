/* custom select */
@mixin custom-select{
    select.input-lg {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        cursor: pointer;
    }
    .form-group{
        position: relative;
    }
    .form-control{
        width: 143px;
        height: 50px;
        font-family: $font-family;
        font-size: 15px;
        letter-spacing: 0.38px;
        // text-transform: uppercase;
    }
    select + img {
    position: absolute;
    bottom: 18px;
    margin-left: 150px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 5px;
    }
}
@mixin form-btn {
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 10px;
    font-family: $font-family;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    width: 190px;
    height: 60px;
    margin-top: 14px;
}
.zone-page {
    display: flex;
    .theme-bg {
        background: url("../../images/bg.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        background: unset;
        border-bottom: 1px solid white;
        border-radius: unset;
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #1d1d1d inset;
        transition: background-color 5000s ease-in-out 0s;
        caret-color: white;
      }
    .content {
      flex: 1 1 1420px;
      .zone-block {
        .zone-header {
          padding: 20px 0 0 65px;
          @media screen and (max-width: $large-one) {
            padding-top: 20px;
          }
          i {
            width: 21.21px;
            height: 14px;
            margin-right: 47px;
            @media screen and (max-width: $large-two) {
              margin-right: 15px;
            }
          }
          .brand-logo {
            margin-right:.875rem;
            display:flex;
            flex-shrink: 0;
          }
          .brand-logo img {
            width: 31.21px;
            height: 25px;
          }
          .zone-title h3 {
            font-family: $font-family;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 1.05px;
          }
        }
        .zone-content {
          padding-top: 7px;
          .check-height {
            height: 20px;
          }
          .items-wrap {
            max-height: calc(100vh - 286px);
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0px 60px;
            @media screen and (max-width: $extra-large-three) {
                max-height: calc(100vh - 290px);
            }
            .zone-items {
                margin: 0px -1.5rem;
                display: flex;
                flex-grow:1;
                flex-wrap: wrap;
                @media screen and (max-width: $extra-large-one) {
                    margin: 0px -1rem;
                }
            }
          }
          .zone-info {
            flex: 1 1 50%;
            padding: 0px 1.5rem;
            margin-bottom: 25px;
            width: 50%;
            min-width:520px;
            @media screen and (max-width: $extra-large-one) {
              margin-bottom: 10px;
            }
            .zone-top {
              margin-bottom: 10px;
              h1 {
                font-family: $font-family;
                font-size: 30px;
                line-height: 36px;
                letter-spacing: 1.5px;
                margin-bottom: 0;
                text-shadow: 0px 0px 10px #252525;
                text-transform: uppercase;
              }
              p {
                font-family: $font-family;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.75px;
                color: #dddddd;
                text-shadow: 0px 0px 10px #252525;
                margin-bottom: 0;
              }
            }
            .zone-details {
              background: #252525 0% 0% no-repeat padding-box;
              box-shadow: 0px 0px 15px #00000080;
              border-radius: 20px;
              padding: 10px 15px;
              p {
                color: white;
                font-family: $font-family;
                font-size: 15px;
                font-weight: bold;
                line-height: 18px;
                letter-spacing: 0px;
                span {
                  padding: 0 5px;
                  font-size: 10px;
                  line-height: 12px;
                  letter-spacing: 0;
                  color: #dddddd;
                  font-weight: 600;
                }
              }
              .upper-zone, .lower-zone {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
              }
              .sub-details {
                p{
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0;
                    font-weight: unset;
                }
              }
              .custom-width{
                padding-right: 15px;
                p{
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: unset;
                    margin-bottom: 0;
                    // word-break: break-word;
                }
               }
            }
          }
          .perform-calculation {
            width: 225px;
            height: 60px;
            box-shadow: 0px 0px 10px #00000080;
            border-radius: 10px;
            font-size: 15px;
            line-height: 18px;
            font-family: $font-family;
            letter-spacing: 0.75px;
            font-weight: bold;
          }
          .custom-width{
            p,.form-group{
                &:nth-child(1){
                    width: 30px;
                }
                &:nth-child(2),&:nth-child(3){
                    width: 95px;
                }
                &:nth-child(4),&:nth-child(5){
                    width: 60px;
                }
            }
            .form-group{
                margin-bottom: 9px;
                @include custom-select;
                    select + img {
                    position: absolute;
                    bottom: 14px;
                    margin-left: 65px;
                    @media screen and (max-width:$extra-large-two) {
                        margin-left: 53px;
                    }
                }
                .form-control{
                    height: 25px;
                    padding: 5px;
                    text-transform: capitalize;
                    line-height: 14px;
                    font-size: 12px;
                    width: 100% !important;
                }
            }
        }
        }
      }
    }
    .sidebar {
      background: #252525;
      box-shadow: 0px 0px 10px #00000080;
      width: 490px;
      .zone-sidebar {
        padding: 0px 40px;
      }
      .sidebar-header {
        padding: 10px 0 20px 0;
        h2 {
          font-family: $font-family;
          font-size: 25px;
          line-height: 30px;
          letter-spacing: 1.25px;
          i {
            width: 34px;
          }
        }
      }
      .sidebar-content {
        height: calc(100vh - 180px);
        min-height: 500px;
        .source-list {
            height: 45%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
          h1 {
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 2.5px;
          }
        }
        .used-light-source {
            height: calc(60% - 85px);
          .used-light-source-title {
            margin-bottom: 21px;
            h3 {
              font-family: $font-family;
              font-size: 20px;
              line-height: 24px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
          .used-light-source-list {
            padding-right:2px;
            // margin-right: 15px;
            width: 400px;
            cursor: pointer;
            &:hover{
                overflow-y: auto;
            }
            p {
              margin-bottom: 0;
              color: white;
              font-family: $font-family;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.75px;
              text-transform: uppercase;
              margin-left: 28px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .my-sources-light-source {
          margin-bottom: 15px;
          height: calc(100% - 60px);
          .my-sources-light-source-title {
            margin-bottom: 21px;
            h3 {
              font-family: $font-family;
              font-size: 20px;
              line-height: 24px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
          .my-sources-light-source-list {
            overflow: auto;
            height:100%;
            // overflow: hidden;
            padding-right:2px;
            // margin-right: 15px;
            width: 400px;
            cursor: default;
            &:hover{
                overflow-y: auto;
            }
            p {
              margin-bottom: 0;
              color: white;
              font-family: $font-family;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.75px;
              text-transform: uppercase;
              margin-left: 28px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .sidebar-bottom {
        text-align: center;
        margin-bottom: 32px;
        img {
          width: 150px;
          height: 27.67px;
        }
      }
    }
  }

  .zone-block {
    label {
      font-family: $font-family;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: white;
    }
    .zone-content {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        background: unset;
        border-bottom: 1px solid white;
        border-radius: unset;
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #1d1d1d inset;
        transition: background-color 5000s ease-in-out 0s;
        caret-color: white;
      }
      .zone-form {
        margin-bottom: 10px;
        .zone-form-row {
            display: flex;
            flex-wrap:wrap;
        }
        @include custom-select;
        @media screen and (max-width: $extra-large-two) {
          select {
            width: 150px !important;
          }
          select + img {
            margin-left: 122px;
          }
        }
        @media screen and (max-width: $large-two) {
          select {
            width: 138px !important;
          }
          select + img {
            margin-left: 112px;
          }
        }
        .form-group {
          margin-bottom: 0;
        }
        select {
          margin-right: 15px;
        }
        input {
          // width: 135px !important;
          height: 25px !important;
          border: unset;
          background: unset;
          border-bottom: 1px solid white;
          border-radius: unset;
          padding-left: 0;
          color: white;
          // text-transform: uppercase;
          font-size: 12px !important;
          line-height: 14px !important;
          letter-spacing: 0.6px;
          margin-right: 40px;
          &:focus {
            border-bottom: 1px solid white !important;
          }
        }
        .border-none {
          border: unset !important;
        }
      }
    }
  }

  .zones-list-data {
    overflow: auto;
    max-height: 150px;
    max-height: 150px;
    // overflow: hidden;
    margin-bottom: 10px;
    padding-right:2px;
    margin-right: 15px;
    cursor: pointer;
    &:hover{
        overflow-y: auto;
    }
  }

  .zonesmix-list-data {
    overflow: auto;
    max-height: 70px;
    max-height: 70px;
    // overflow: hidden;
    margin-bottom: 10px;
    padding-right:2px;
    margin-right: 15px;
    cursor: pointer;
    &:hover{
        overflow-y: auto;
    }
  }
  .details-block-single {
    height: 250px;
    overflow: hidden;
    margin-bottom: 10px;
    padding-right:5px;
    overflow-y: auto;
}
.style{
    margin-right: -10px;
}
.details-block {
    height: 200px;
    overflow: hidden;
    margin-bottom: 10px;
    padding-right: 5px;
    overflow-y: auto;
    h5 {
      color: $white;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #252525;
    z-index: 1;
  }

@mixin all-form-inputs{
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000040;
    border-radius: 5px;
}

#rotationModal{
    .rotation-option{
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        @include custom-select;
       select{
           width: 275px !important;
       }
       select + img {
        margin-left: 245px;
    }
        margin-bottom: 46px;
    }

    .btn{
        @include form-btn;
    }
}

#obstructModal{
    .btn{
        @include form-btn;
    }
}
.system-dropdown {
    font-size: 14px !important;
    height: 30px !important;
}
.mr-25 {
    margin-right: 25px;
}

.close-option {
    .close-option-icon {
      visibility: hidden;
  }
}

.close-option:hover {
  .close-option-icon {
    visibility: visible;
    display: inline;
    height: 25px;
}
}

.my-source-title {
  font-family: "proxima-nova";
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.mirror-check-box {
  margin-top: 25px;
  margin-right: 0;
  width: 96px;
  display: flex;
}

.disabled-div {
  background-color: dimgray !important;
  opacity: 0.8;
  .sticky {
    background: #696969!important;
  }
}
.section-scroll{
  padding: 10px !important;
  transition: background-color 0.2s ease !important;
  height: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-top: 0 !important;
}
.disable-pointer {
  pointer-events: none !important;
}

.zone-page .sidebar .result-content {
  width: 390px;
  margin: 0px 45px;
  position: relative;
  height: calc(100vh - 80px);
}

.zone-page .sidebar .result-content .result-listing .zonewise-listing {
  margin-bottom: 28px;

}

.zone-page .sidebar .result-content .result-listing .zonewise-listing h3 {
  font-family: "proxima-nova";
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 1.05px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: white;
}

.zone-page .sidebar .result-content .result-listing .zonewise-listing p {
  font-family: "proxima-nova";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  margin-bottom: 6px;
  color: white;
}

.zone-page .sidebar .result-content .result-listing .zonewise-listing .color-green {
  color: #00C43A;
  padding-right: 25px;
}

.zone-page .sidebar .result-content .result-listing .zonewise-listing .color-red {
  color: #E62629;
  padding-right: 25px;
}

.sidebar .result-listing {
  margin-bottom: 40px;
  height: calc(100vh - 350px);
}

.sidebar .result-listing .btn {
  width: 169px !important;
  -webkit-box-shadow: 0px 0px 10px #00000033;
          box-shadow: 0px 0px 10px #00000033;
  border-radius: 10px;
  font-family: "proxima-nova";
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.75px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  width: 190px;
  height: 60px;
  margin-top: 14px;
}

.zone-result {
  overflow-y: auto !important;
  height: 100% !important;
}

.zone-page-name{
  z-index: 1035;
}

.zone-page-owner{
  z-index: 1036;
}

.header-input{
  width: 300px !important;
  height: 25px !important;
  border: unset;
  background: unset;
  border-bottom: 1px solid white !important;
  border-radius: unset;
  padding-left: 0;
  padding-bottom: 0;
  color: white !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.38px;
  margin-right: 30px;
  &:focus{
    background: unset !important;
    border-bottom: 1px solid white !important;
  }
}
.zone-tooltip{
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;
}
.disable-zone {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}
.result-pdf {
  visibility: hidden;
  height: 1px;
  overflow: hidden;
}
.light-width {
  flex: 0 0 73% !important;
  max-width: 73% !important;
}
.gw-flex-zone{
  flex-grow: 1;
  width: calc(100% - 490px);
}

.fixed-loader {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1041;
  background: rgba(0, 0, 0, 0.5)
}


.custom-accordion {
  .card {
    background-color: #252525;
    border: none;
    .card-body {
      padding: .75rem;
      .zone-sidebar-list {
        margin-bottom: .5rem;
      }
      div:last-child {
        .zone-sidebar-list {
          margin-bottom: 0;
        }
      }
    }
  }
  .card-header {
    background-color: #252525;
    color: white;
    padding: .75rem 1.5rem .75rem 2.5rem;
    position: relative;
    i {
      position: absolute;
      left: 1rem;
      top: 50%;
      margin-top: -8px;
      transition: all 0.2s ease !important;
    }
    &.active {
      i {
        transform: rotate(90deg);
      }
    }
  }
}


